<template>
  <div fluid style="background-color: white">
    <section>
      <div class="header">
        <!--Content before waves-->
        <div
          class="inner-header"
          style="align-items: center; justify-content: center; display: flex"
        >
          <!--Just the logo.. Don't mind this-->
          <v-rows>
            <v-col cols="12">
              <v-img
                class="logoenter mx-auto"
                max-width="300px"
                src="@/assets/1white.png"
              ></v-img>
            </v-col>
            <v-col  cols="12">
              <h1 class="logoenter" style="transition-delay: 0.2s">Daskrans Spring Water</h1>
            </v-col>
            <v-col  cols="12">
              <p class="logoenter" style="transition-delay: 0.4s">
                The life blood of water, straight from the earth, as it was
                meant to be
              </p>
            </v-col>
          </v-rows>
        </div>

        <!--Waves Container-->
        <div>
          <svg
            class="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shape-rendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g class="parallax">
              <use
                xlink:href="#gentle-wave"
                x="48"
                y="0"
                fill="rgba(255,255,255,0.7"
              />
              <use
                xlink:href="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(255,255,255,0.5)"
              />
              <use
                xlink:href="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(255,255,255,0.3)"
              />
              <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
        <!--Waves end-->
      </div>
      <!--Header ends-->

      <!--Content ends-->
    </section>
    <!-- <section>
      <div class="text-center mt-4"><h1 style="font-size : 48px">Photos from the ground</h1></div>
      <v-container>
      <v-carousel style="border-radius:8px" cycle height="76vh">
        <v-carousel-item>
          <v-sheet height="100%">
            <v-row class="fill-height" align="center" justify="center">
              <v-img
                src="https://images.unsplash.com/photo-1564401003800-3cd8ced29bbe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
              >
              </v-img>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
          <v-sheet height="100%">
            <v-row class="fill-height" align="center" justify="center">
              <v-img
                src="https://images.unsplash.com/photo-1564401003800-3cd8ced29bbe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
              ></v-img>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
          <v-sheet height="100%">
            <v-row class="fill-height" align="center" justify="center">
              <v-img
                src="https://images.unsplash.com/photo-1564401003800-3cd8ced29bbe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
              ></v-img>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      </v-container>
    </section> -->
    <section>
      <v-container class="py-8" style="max-width: 80vw">
        <v-row align="center">
          <v-col class="px-md-8" cols="12" md="6">
            <h1 v-scrollanimation style="font-size: 36px">
              Welcome to Daskrans Spring Water
            </h1>
            <p v-scrollanimation style="transition-delay: 0.1s">
              We understand the importance of clean, mineral rich water and we
              have decided to share that with the world in the form of Daskrans
              Spring Water, water is completely untreated and is naturally
              filtered through multiple layers of soil, many tests have been
              done on the water and it is found to have no impurities other than
              the minerals to make it life giving water.
            </p>
            <p v-scrollanimation style="transition-delay: 0.1s">
              The water comes from deep within the Daskrans hill range and has
              been running without stop for what we believe to be 100s of years
              and through thorough testing have come to the conclusion that the
              spring will not stop for multiple lifetimes
            </p>
            <v-btn
              v-scrollanimation
              style="transition-delay: 0.1s"
              to="/about"
              class="primary"
              >About us</v-btn
            >
          </v-col>
          <v-col cols="12" md="6">
            <v-img
              eager
              style="border-radius: 8px"
              src="https://images.unsplash.com/photo-1631472548473-3e1a1c74baeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="primary py-8" style="background-color: white">
      <v-container class="mt-4" style="max-width: 80vw">
        <v-row align="center">
          <v-col cols="12" md="6">
            <v-img
              max-height="480px"
              max-width="480px"
              eager
              cover
              class="mx-auto"
              style="border-radius: 8px"
              src="@/assets/background/image4281.png"
            ></v-img>
          </v-col>
          <v-col class="px-md-8 white--text" cols="12" md="6">
            <h1 v-scrollanimation style="font-size: 36px">
              The future leader in spring water combined with decentralised
              technology
            </h1>
            <p v-scrollanimation style="transition-delay: 0.1s">
              At Daskrans we saw an opportunity to combine technology and water,
              we did this in the form of developing and partnering with
              cryptocurrency technology and amalgamating spring water with this
              to form the Aetera Aqua Token.
            </p>
            <p v-scrollanimation style="transition-delay: 0.1s">
              This comes with the global demand for a clean untouched water
              supply as with all the disaster running strife around the world
              such as droughts, sanitation problems and lack of access to clean
              water to name a few.
            </p>
            <p v-scrollanimation style="transition-delay: 0.1s">
              Aetera Token | Investing opportunity coming soon
            </p>
            <v-btn v-scrollanimation style="transition-delay: 0.1s" href="https://www.aetera.co.za" target="_none"
              >Aetera Token</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="secondary">
      <v-container class="white--text" style="max-width: 80vw">
        <v-row align="center" justify="center" class="text-center">
          <v-col cols="auto">
            <h1 style="font-size: 24px">Known to break boundaries</h1>
          </v-col>
          <v-divider class="white my-4 hidden-sm-and-down" vertical />
          <v-col cols="auto">
            <h1 style="font-size: 24px">
              Water is the driving force of all nature
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <!-- <section class="mt-8">
      <v-container class="mt-4" style="max-width: 80vw">
        <v-row align="center" justify="center" class="text-center">
          <h1 style="font-size: 48px">Who we associate ourselves with</h1>
          <v-col cols="12">
            <carousel :per-page="3" :mouse-drag="true">
              <slide>
                <v-img
                  href="www.aetera.co.za"
                  target="_blank"
                  class="mx-auto"
                  src="@/assets/logoaeterablack.png"
                  max-width="96px"
                ></v-img>
                <p class="mt-8">Aetera Aqua Token</p>
              </slide>
              <slide
                ><v-img
                  class="mx-auto"
                  src="@/assets/examplelogo.jpg"
                  max-width="96px"
                ></v-img>
                <p class="mt-2">Example Company</p></slide
              >
              <slide
                ><v-img
                  class="mx-auto"
                  src="@/assets/examplelogo.jpg"
                  max-width="96px"
                ></v-img>
                <p class="mt-2">Example Company</p>
              </slide>
              <slide>
                <v-img
                  class="mx-auto"
                  src="@/assets/examplelogo.jpg"
                  max-width="96px"
                ></v-img>
                <p class="mt-2">Example Company</p>
              </slide>
              <slide>
                <v-img
                  class="mx-auto"
                  src="@/assets/examplelogo.jpg"
                  max-width="96px"
                ></v-img>
                <p class="mt-2">Example Company</p>
              </slide>
              <slide>
                <v-img
                  class="mx-auto"
                  src="@/assets/examplelogo.jpg"
                  max-width="96px"
                ></v-img>
                <p class="mt-2">Example Company</p>
              </slide>
            </carousel>
          </v-col>
        </v-row>
      </v-container>
    </section> -->
    <!-- <section class="pt-8 pb-8 primary white--text">
      <v-container class="mt-4 py-8" style="max-width: 80vw">
        <v-row align="center" justify="center" class="text-center">
          <v-col cols="12">
            <h1 style="font-size: 48px">What people have to say</h1>
          </v-col>
          <v-col cols="auto">
            <v-card class="mx-auto secondary" dark max-width="400">
              <v-card-title>
                <v-icon large left> mdi-map-marker-outline </v-icon>
                <span
                  class="text-h6 font-weight-bold"
                  style="font-family: Cinzel !important"
                  >South Africa</span
                >
              </v-card-title>

              <v-card-text class="text-h5 white--text">
                <span style="font-family: Comfortaa">"Insert Quote Here"</span>
              </v-card-text>

              <v-card-actions>
                <v-list-item>
                  <v-list-item-avatar color="transparent">
                    <v-img
                      class="elevation-6"
                      alt=""
                      src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-left"
                      >Joane Jansen van Vuuren</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card class="mx-auto secondary darken-1" dark max-width="400">
              <v-card-title>
                <v-icon large left> mdi-map-marker-outline </v-icon>
                <span
                  class="text-h6 font-weight-bold"
                  style="font-family: Cinzel !important"
                  >Germany</span
                >
              </v-card-title>

              <v-card-text class="text-h5 white--text">
                <span style="font-family: Comfortaa">"Insert Quote Here"</span>
              </v-card-text>

              <v-card-actions>
                <v-list-item>
                  <v-list-item-avatar color="transparent">
                    <v-img
                      class="elevation-6"
                      alt=""
                      src="https://avataaars.io/?avatarStyle=Circle&topType=Hat&accessoriesType=Prescription01&facialHairType=BeardMedium&facialHairColor=Brown&clotheType=ShirtCrewNeck&clotheColor=Red&eyeType=Surprised&eyebrowType=DefaultNatural&mouthType=Eating&skinColor=Light"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-left"
                      >Carl Swartz</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card class="mx-auto secondary" dark max-width="400">
              <v-card-title>
                <v-icon large left> mdi-map-marker-outline </v-icon>
                <span
                  class="text-h6 font-weight-light font-weight-bold"
                  style="font-family: Cinzel !important"
                  >Australia</span
                >
              </v-card-title>

              <v-card-text class="text-h5 white--text">
                <span style="font-family: Comfortaa">"Insert Quote Here"</span>
              </v-card-text>

              <v-card-actions>
                <v-list-item>
                  <v-list-item-avatar color="transparent">
                    <v-img
                      class="elevation-6"
                      alt=""
                      src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription01&hairColor=Blue&facialHairType=Blank&clotheType=Hoodie&clotheColor=Red&eyeType=WinkWacky&eyebrowType=UnibrowNatural&mouthType=Smile&skinColor=DarkBrown"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-left"
                      >Kieran Lester</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section> -->
    <section class="mt-8">
      <v-container class="my-4 text-center" style="max-width: 80vw">
        
         <h1 style="font-size: 48px">Read these popular articles</h1>
          <p>See the following articles to learn more about what exactly Daskrans wants to acheive and how we formulate our goals and future objectives</p>
          <v-row align="center" justify="center" class="text-center">
            <v-col cols="12" md="4">
              <v-card height="100%">
                <v-img src="">

                </v-img>
                <v-card-title class="text-left" primary-title>
                  <div>
                    <h2 class="ml-0 " style="word-break: break-word;">Shifts in regional water availability due to global tree restoration</h2>
                    <p style="word-break: break-word;">In June 2021, the United Nations declared the Decade on Ecosystem Restoration to prevent, halt and reverse the degradation of ecosystems worldwide. </p>
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-btn href="https://www.uvic.ca/news/media/2022+expert-qa-water-crisis-climate-change+expert-advisory" target="_blank"  color="secondary">Read More</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card height="100%">
                <v-img src="">

                </v-img>
                <v-card-title class="text-left" primary-title>
                  <div>
                    <h2 class="ml-0 " style="word-break: break-word; " >Expert Q&A on water crisis amidst climate change</h2>
                    <p style="word-break: break-word;">The threat to water security is just as urgent as the climate crisis, says Oliver M. Brandes, project lead of the POLIS Water Sustainability Project at the University of Victoria.</p>
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-btn href="https://www.nature.com/articles/s41561-022-00935-0" target="_blank" color="secondary">Read More</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card height="100%">
                <v-img src="">

                </v-img>
                <v-card-title class="text-left" primary-title>
                  <div>
                    <h2 style="word-break: break-word;"  class="ml-0">How safe is your tap water?</h2>
                    <p style="word-break: break-word;">We are told to drink at least eight glasses of water a day to keep healthy, but how safe is drinking it from our taps? Is it time to switch to bottled water?</p>
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-btn href="https://www.dailymaverick.co.za/article/2022-05-09-how-safe-is-your-tap-water/" target="_blank" color="secondary">Read More</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "HelloWorld",
  components: {
    Carousel,
    Slide,
  },

  data: () => ({
    messages: [
      {
        avatar: "https://avatars0.githubusercontent.com/u/9064066?v=4&s=460",
        name: "Sarah Waterman",
        title: "The water of the future",
        text: "Water so delicious and really respect the team",
      },
      {
        avatar: "https://avatars0.githubusercontent.com/u/9064066?v=4&s=460",
        name: "John Leider",
        title: "Great Tech!",
        text: "Water so delicious and really respect the team",
      },
      {
        avatar: "https://avatars0.githubusercontent.com/u/9064066?v=4&s=460",
        name: "Lenny Oberholzer",
        title: "Outstanding Ideas!",
        text: "Water so delicious and really respect the team",
      },
    ],
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
    importantLinks: [
      {
        text: "Documentation",
        href: "https://vuetifyjs.com",
      },
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com",
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuejs.com/vuetify",
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs",
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify",
      },
    ],
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com/components/api-explorer",
      },
      {
        text: "Select a layout",
        href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
      },
      {
        text: "Frequently Asked Questions",
        href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
      },
    ],
  }),
};
</script>
<style scoped>
@import url(https://fonts.googleapis.com/css2?family=Cinzel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);

body {
  margin: 0;
}
p {
  font-family: "Comfortaa", cursive;
}
h1 {
  font-family: "Cinzel", serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 64px;
}

.header {
  position: relative;
  text-align: center;
  background: linear-gradient(
    180deg,
    rgba(103, 177, 255, 1) 0%,
    rgba(36, 116, 200, 1) 49%,
    rgba(1, 45, 92, 1) 100%
  );
  color: white;
}
.logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.inner-header {
  height: 67vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */
.logoenter {
  animation: logo;
  animation-duration: 2s;
}
@keyframes logo {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
}
.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease-out;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
}
</style>
