<template>
  <v-app id="inspire">
    <v-app-bar height="96px" elevate-on-scroll app color="white">
      <v-container class="py-0 fill-height">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="hidden-sm-and-up"
        ></v-app-bar-nav-icon>
        <router-link to="/">
          <v-img
            class="mr-2"
            contain
            max-height="80px"
            max-width="80px"
            src="@/assets/1.png"
          ></v-img>
        </router-link>
        <v-toolbar-title
          class="mr-4 hidden-sm-and-down"
          style="
            font-family: 'Cinzel', serif;
            font-size: 24px;
            font-weight: 700;
          "
        >
          Daskrans</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <div class="hidden-sm-and-down">
          <v-btn
            color="primary"
            :to="link.link"
            v-for="link in links"
            :key="link"
            text
          >
            {{ link.text }}
          </v-btn>

          <v-btn class="ml-2" href="https://www.aetera.co.za" target="_none" tile elevation="2" color="secondary"
            >Aetera Token</v-btn
          >
        </div>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img src="@/assets/logoaeterablack.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6"> Daskrans </v-list-item-title>
            <v-list-item-subtitle>Spring Water</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="secondary--text text--accent-4"
        >
          <router-link style="text-decoration: none" to="/about">
            <v-list-item>
              <v-list-item-title>About Us</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link style="text-decoration: none" to="/wheretobuy">
            <v-list-item>
              <v-list-item-title>How to buy</v-list-item-title>
            </v-list-item>
          </router-link>
          <!-- <router-link style="text-decoration: none" to="/thepeople">
            <v-list-item>
              <v-list-item-title>The People</v-list-item-title>
            </v-list-item>
          </router-link> -->
          <router-link style="text-decoration: none" to="/contactus">
            <v-list-item>
              <v-list-item-title>Contact Us</v-list-item-title>
            </v-list-item>
          </router-link>
          <v-list-item>
            <a style="text-decoration: none" href="https://www.aetera.co.za" target="_none">Aetera Aqua Token</a>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-slide-y-reverse-transition mode="out-in">
        <router-view />
      </v-slide-y-reverse-transition>
    </v-main>
    <v-footer padless>
      <v-card flat tile width="100%" class="blue lighten-1 text-center">
        <v-card-text>
          <v-btn v-for="icon in icons" :key="icon" class="mx-4" icon>
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} —
          <strong>Daskrans | Spring Water</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    links: [
      { text: "About Us", link: "/about" },
      { text: "How to buy", link: "/wheretobuy" },
      // { text: "The People", link: "/thepeople" },
      { text: "Contact us", link: "/contactus" },
    ],
    drawer: false,
  }),
};
</script>
<style scoped>
@import url(https://fonts.googleapis.com/css2?family=Cinzel&display=swap);

.router-link {
  text-decoration: none;
}
</style>
